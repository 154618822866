import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class ServicoService extends MainService {
  usuario = { id: '', nome: '' };
  isFromRoute = ''; //Variável criada para obter a rota anterior do administradaor, com o objetivo de ao cancelar, redirecionar o administrador novamente para tela anterior.
  constructor(private http: HttpClient) {
    super();
  }

  getCategoriaList() {
    const url = `${super.getUrl()}categoria/list`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }

  getCategoria(idSubcategoria: string) {
    const url = `${super.getUrl()}categoria/getSub/${idSubcategoria}`;
    return this.http.get<any>(url, this.getOptions());
  }

  getSubcategoriaList(idCategoria: string) {
    const url = `${super.getUrl()}subcategoria/list`;
    return this.http.post<any[]>(
      url,
      { id_categoria: idCategoria },
      this.getOptions()
    );
  }

  addServico(servico: any) {
    const url = `${super.getUrl()}servico/add`;
    return this.http.post<any[]>(url, servico, this.getOptions());
  }

  updateServico(servico: any) {
    const url = `${super.getUrl()}servico/update`;
    return this.http.post<any[]>(url, servico, this.getOptions());
  }

  getServicoList(filter: any) {
    const url = `${super.getUrl()}servico/list`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  getServico(id: any) {
    const url = `${super.getUrl()}servico/get/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  delete(id: string) {
    const url = `${super.getUrl()}servico/delete/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  setDestaque(id: string) {
    const url = `${super.getUrl()}servico/destacar/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  searchServico(term: string): Observable<any> {
    if (!term.trim()) {
      return of([]);
    }

    const url = `${super.getUrl()}servico/searchReturnNames`;
    //return this.http.post<any[]>(url, term, this.getOptions())

    return this.http.post<any>(url, { nome: term }).pipe(
      tap((x) =>
        x.length
          ? this.log(`encontrado com termo "${term}"`)
          : this.log(`não encontrado com termo "${term}"`)
      ),
      catchError(this.handleError<any>('searchServico', []))
    );
  }

  searchReturnList(filter: any) {
    const url = `${super.getUrl()}servico/searchReturnList`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  destaqueServicos(filter: any) {
    const url = `${super.getUrl()}servico/destaqueServicos`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  search(filter: any) {
    const url = `${super.getUrl()}servico/search`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  getServicoIdPessoa(id_pessoa: any) {
    const url = `${super.getUrl()}servico/getServicoIdPessoa`;
    return this.http.post<any>(url, { id_pessoa }, this.getOptions());
  }

  getCategoriaCompleta(filter: any) {
    const url = `${super.getUrl()}categoria/get`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  getSubcategoriaCompleta(filter: any) {
    const url = `${super.getUrl()}subcategoria/get`;
    return this.http.post<any>(url, filter, this.getOptions());
  }

  private log(message: string) {
    console.log(message);
    //this.messageService.add(`HeroService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
