import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicoService } from '../service/servico.service';
import { Location } from '@angular/common';
import { EventService, EventNames } from '../service/event.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';
import { resourceLimits } from 'worker_threads';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-servico',
  templateUrl: './lista-servico.component.html',
  styleUrls: ['./lista-servico.component.css'],
})
export class ListaServicoComponent implements OnInit {
  filtro: any = {};
  isAdmin = false;
  categoriaList: any;
  subcategoriaList: any;
  subcategoriaDisabledToggle = true;
  produtoList: any;
  tipoCadastroList = [
    { id: 'S', nome: 'Serviço' },
    { id: 'P', nome: 'Produto' },
  ];

  constructor(
    private servicoService: ServicoService,
    private router: Router,
    private location: Location,
    private event: EventService,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isAdmin =
      this.authService.getUser().nome_perfil == 'Administrador' ? true : false;

    window.scroll({ top: 0, left: 0 });
    this.filtro.tipo =
      this.route.snapshot.data['tipo'] == 'produto'
        ? 'P'
        : this.route.snapshot.data['tipo'] == 'servico'
        ? 'S'
        : '';

    this.event.subscribe(EventNames.SHOULD_UPDATE_PRODUCT_LIST, () => {
      this.getProdutoList(0);
    });

    this.getCategoriaList();
    this.filtro.id_categoria = '';
    this.filtro.id_subcategoria = '';
    this.produtoList = [];
    this.getProdutoList(this.paginaAtual);
  }

  getCategoriaList() {
    this.servicoService
      .getCategoriaList()
      .subscribe((list) => (this.categoriaList = list));
  }

  getSubcategoriaList() {
    this.subcategoriaList = [];
    //this.subcategoriaDisabled = "disabled";
    this.subcategoriaDisabledToggle = true;
    this.filtro.id_subcategoria = '';
    if (this.filtro && this.filtro.id_categoria) {
      this.servicoService
        .getSubcategoriaList(this.filtro.id_categoria)
        .subscribe((list) => {
          this.subcategoriaList = list;
          if (this.subcategoriaList.length > 0) {
            //this.subcategoriaDisabled = "";
            this.subcategoriaDisabledToggle = false;
          }
        });
    }
  }

  getTipoCadastroList() {
    this.subcategoriaList = [];
    //this.subcategoriaDisabled = "disabled";
    this.subcategoriaDisabledToggle = true;
    this.filtro.id_subcategoria = '';
    if (this.filtro && this.filtro.id_categoria) {
      this.servicoService
        .getSubcategoriaList(this.filtro.id_categoria)
        .subscribe((list) => {
          this.subcategoriaList = list;
          if (this.subcategoriaList.length > 0) {
            //this.subcategoriaDisabled = "";
            this.subcategoriaDisabledToggle = false;
          }
        });
    }
  }

  qtdPaginas = 0;
  qtdItensPorPagina = 15;
  paginaAtual = 0;

  getProdutoList(pagina: number) {
    this.paginaAtual = pagina;

    if (
      this.router.url.replace('/', '') == 'adm-servico' ||
      this.router.url.replace('/', '') == 'adm-servico-lista'
    ) {
      this.filtro.id_usuario = this.servicoService.usuario.id
        ? this.servicoService.usuario.id
        : this.authService.getUser().id;
    } else {
      this.filtro.id_usuario = this.authService.getUser().id;
    }

    this.filtro.offset = pagina * this.qtdItensPorPagina;
    this.filtro.limit = this.qtdItensPorPagina;

    this.servicoService.getServicoList(this.filtro).subscribe((list) => {
      list.result.rows.forEach((x: any) => {
        //x.foto = x.foto ? `${this.servicoService.getUrl()}images/${x.foto}` : 'assets/images/produto.svg';
        // x['Subcategorium.Categorium.nome']
        if (x['Subcategorium.Categorium.nome'] == 'Afroempreendedores') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Baiana') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Capoeira') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Arte & Cultura') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Flora') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Gastronomia') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Moda & Beleza') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Religião') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
        } else if (x['Subcategorium.Categorium.nome'] == 'Turismo') {
          x.foto =
            x.foto ||
            'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
        } else {
          x.foto = x.foto || 'assets/images/produto.svg';
        }
        //x.valor = x.valor.replace('.', ',');
      });

      this.produtoList = list.result;
      this.qtdPaginas = Math.ceil(
        this.produtoList.count / this.qtdItensPorPagina
      );
    });
  }

  filtrar() {
    this.getProdutoList(0);
  }

  Destacar(id: any) {
    this.servicoService.setDestaque(id).subscribe((res) => {
      if (res[0] === 1) {
        this.toastr.success('Destaque modificado com sucesso', 'Sucesso');
      } else {
        this.toastr.error('Erro ao modificar destaque', 'Error');
      }

      this.getProdutoList(0);
    });
  }

  Editar(id: string, tipo: string) {
    this.event.publish(EventNames.SHOULD_EDIT_SERVICO, id);
    // if (tipo == 'S') {
    //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //   window.scrollTo(0, 200);
    //   this.router.navigate([`servico/${id}`]);
    //   //this.event.publish(EventNames.SHOULD_EDIT_SERVICO, id);
    // }
    // else {
    //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //   window.scrollTo(0, 200);

    //   this.router.navigate([`produto/${id}`]);
    //   //this.event.publish(EventNames.SHOULD_EDIT_PRODUCT, id);
    // }
  }

  Detete(id: string) {
    const frase = 'Deseja realmente apagar o registro?';
    Swal.fire({
      title: '',
      text: frase,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.value) {
        this.servicoService.delete(id).subscribe((_) => {
          this.toastr.success('Apagado com sucesso', 'Sucesso');
          this.getProdutoList(0);
        });
      }
    });
  }
}
