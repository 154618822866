<!-- <div class="{{ tipo == 'externo' ? 'bg-vermelho' : tipo == 'interno' ? 'bg-azul' : ''}} barra-titulo">
  <h2>Produtos/Serviços</h2>
</div> -->
<main>

  <div class="conteudo">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item redirect" routerLink="/home">Página inicial </li>
        <li class="breadcrumb-item active" aria-current="page">Cadastro de produtos e serviços</li>
      </ol>
    </nav>

    <h2> Cadastro de Produtos e serviços</h2>

    <p *ngIf="tipo != 'interno'">
      <strong>Preencha com cuidado e atenção.</strong> As informações fornecidas irão apoiar a criação de políticas
      públicas para o desenvolvimento do turismo étnico-afro de Salvador.
    </p>


    <div class="header-title" *ngIf="servicoService.usuario.id">
      <h2>
        <span>Pessoa/Entidade</span>
        {{servicoService.usuario.nome}}
      </h2>
      <button type="button" class="btn btn-transparent" (click)="redirecionarDadosGerais()"><i
          class="fas fa-chevron-left"></i> voltar para dados gerais</button>
    </div>
    <div class="guias-cadastro">
      <button *permission="['add-product-or-service']" type="button"
        class="btn btn-transparent {{ tipoTela == 'cadastro' ? 'active': '' }}" (click)="mudarTela('cadastro', false)">
        <i class="fas fa-plus"></i>
        {{ edit ? "Atualização" : "Cadastro" }} de Produtos e Serviços
      </button>
      <button type="button" class="btn btn-transparent {{ tipoTela == 'lista' ? 'active': '' }}"
        (click)="mudarTela('lista', false)">
        <i class="fas fa-list-ul"></i>
        Lista de Produtos e Serviços
      </button>
    </div>

    <div *ngIf="tipoTela == 'cadastro'">



      <!-- --------------------BLOCO CADASTRO------------------------- -->
      <div class="blocos-cadastro mb-4">

        <div class="flex">
          <div class="bloco-avatar">
            <div class="avatar-upload upload-produto">
              <label for="">
                Inserir foto do {{nomeCadastro}}:
                <a aria-label="Escolha uma foto ou imagem do seu produto ou serviço" data-balloon-pos="up"
                  class="tootip" style="cursor: pointer;">
                  <i class="fas fa-question-circle"></i>
                </a>
              </label>
              <!-- <input type='file' class="upimage" accept=".png, .jpg, .jpeg" (change)="handleFileInput($event.target)" /> -->
              <input type='file' class="upimage" accept=".png, .jpg, .jpeg" (change)="handleFileInput($event)" />
              <div class="avatar-edit">
              </div>
              <div class="avatar-preview">
                <img id="imagePreview" class="image" src='{{ templateFoto }}' />
              </div>
              <div class="botoes" *ngIf="!semFoto">
                <button type="button" class="btn btn-padrao btn-sm" (click)="AlterarFoto()">
                  <i class="fas fa-check"></i>
                  alterar foto
                </button>
                <button type="button" class="btn btn-danger btn-sm" (click)="ExcluirFoto()">
                  <i class="fas fa-times"></i>
                  excluir foto
                </button>
              </div>
            </div>
            <div class="text-center">Tamanho máximo permitido de {{tamanhoMaximoImagem}}Mb</div>

            <div class="form-group" [ngClass]="displayFieldCss(servico.descricao)">
              <label for="">
                Descrição do {{nomeCadastro}}: <span>*</span>
                <a aria-label="Escreva aqui uma descrição pra seu produto/serviço" data-balloon-pos="up" class="tootip"
                  style="cursor: pointer;">
                  <i class="fas fa-question-circle"></i>
                </a>
              </label>
              <textarea [(ngModel)]="servico.descricao" class="form-control" id="" rows="3" placeholder=""
                maxlength="150"></textarea>
              <app-field-error-display [displayError]="isFieldValid(servico.descricao)"
                errorMsg="Por favor, informe a Descrição">
              </app-field-error-display>
            </div>


          </div>
          <div class="col">

            <div class="form-check form-check-inline mb-4 w-100">
              <p>O que deseja cadastrar?</p>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" value="produto" id="radio1" name="tipoCadastro" class="custom-control-input"
                  [ngModel]="tipoCadastro" (ngModelChange)="tipoCadastro=$event;carregaTela()">
                <label class="custom-control-label" for="radio1">Produto</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" value="servico" id="radio2" name="tipoCadastro" class="custom-control-input"
                  [ngModel]="tipoCadastro" (ngModelChange)="tipoCadastro=$event;carregaTela()">
                <label class="custom-control-label" for="radio2">Serviço</label>
              </div>
            </div>

            <div class="custom-control custom-checkbox custom-control-inline" data-toggle="collapse" href=".add-produto"
              role="button" aria-expanded="false" *ngIf="isAdmin">
              <input type="checkbox" class="custom-control-input" id="check1" [checked]="servico.destaque"
                (change)="servico.destaque = !servico.destaque">
              <label class="custom-control-label" for="check1">
                Adicionar como destaque na pagina inicial?
              </label>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="form-group group-select" [ngClass]="displayFieldCss(servico.id_categoria)">
                  <label for="">
                    Categoria do {{nomeCadastro}}: <span>*</span>
                    <a aria-label="Escolha dentro das opções abaixo qual o seu produto ou serviço se encaixa"
                      data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                      <i class="fas fa-question-circle"></i>
                    </a>
                  </label>
                  <select class="form-control" [(ngModel)]="servico.id_categoria"
                    (ngModelChange)="getSubcategoriaList()">
                    <option value="">Escolha a Categoria</option>
                    <option *ngFor="let opt of categoriaList" [value]="opt.id">
                      {{ opt.nome }}
                    </option>
                  </select>
                  <app-field-error-display [displayError]="isFieldValid(servico.id_categoria)"
                    errorMsg="Por favor, informe a Categoria">
                  </app-field-error-display>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="form-group group-select" [ngClass]="displayFieldCss(servico.id_subcategoria)">
                  <label for="">
                    Subcategoria: <span>*</span>
                    <a aria-label="Escolha dentro das opções abaixo qual o seu produto ou serviço se encaixa. dentro da categoria escolhida"
                      data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                      <i class="fas fa-question-circle"></i>
                    </a>
                  </label>
                  <select class="form-control " [(ngModel)]="servico.id_subcategoria"
                    [disabled]="subcategoriaDisabledToggle"
                    (ngModelChange)="getSubcategoriaNome(servico.id_subcategoria)">
                    <option value="">Escolha a Subcategoria</option>
                    <option *ngFor="let opt of subcategoriaList" [value]="opt.id">
                      {{ opt.nome }}
                    </option>
                  </select>
                  <app-field-error-display [displayError]="isFieldValid(servico.id_subcategoria)"
                    errorMsg="Por favor, informe a Subcategoria">
                  </app-field-error-display>
                </div>
              </div>
            </div>

            <div class="row mb-3" *ngIf="subcategoriaSelect == 'Guias de Turismo'">
              <div class="col-lg-4 col-md-5 col-sm-12">
                <div class="form-group mb-sm-0 mb-xs-0" [ngClass]="displayFieldCss(servico.cadastur)">
                  <label _for="">Cadastur: <span>*</span>
                    <a aria-label="Para guias de turismo é necessário o fornecimento do numero do Cadastur"
                      data-balloon-pos=" up" class="tootip" style="cursor: pointer;">
                      <i class="fas fa-question-circle"></i>
                    </a>
                  </label>
                  <input [(ngModel)]="servico.cadastur" type="text" placeholder="Numero do Cadastur"
                    mask="00.000000.00-0 || 00.000.000/0000-00" currencymask="" class="form-control alinharEsquerda"
                    style="text-align: right;">
                  <app-field-error-display *ngIf="subcategoriaSelect == 'Guias de Turismo'"
                    [displayError]="isFieldValid(servico.cadastur)" errorMsg="Por favor, informe o numero do Cadastur">
                  </app-field-error-display>
                </div>
              </div>
              <div class="col-lg-8 col-md-7 col-sm-12 pt-md-4 mb-3">
                <small><strong>O preenchimento deste campo é obrigatório para Guias de Turismo. </strong></small>
              </div>
            </div>

            <div class="form-group" [ngClass]="displayFieldCss(servico.nome)">
              <label for="">
                Nome do {{nomeCadastro}}: <span>*</span>
                <a aria-label="Escreva aqui o nome do seu produto ou serviço" data-balloon-pos="up" class="tootip"
                  style="cursor: pointer;">
                  <i class="fas fa-question-circle"></i>
                </a>
              </label>
              <input [(ngModel)]="servico.nome" type="text" class="form-control" placeholder="Nome do {{nomeCadastro}}"
                maxlength="50">
              <app-field-error-display [displayError]="isFieldValid(servico.nome)" errorMsg="Por favor, informe o Nome">
              </app-field-error-display>
            </div>

            <div *ngIf="tipoCadastro == 'servico'">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">
                      Agenda e disponibilidade:
                      <a aria-label="Descreva de forma objetiva os dias e horários disponíveis de atendimento do seu serviço"
                        data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <textarea [(ngModel)]="servico.disponibilidade" class="form-control" rows="3"
                      placeholder="Agenda e disponibilidade" maxlength="150"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="">
                      Duração de serviço:
                      <a aria-label="Insira o tempo de duração do seu serviço" data-balloon-pos="up" class="tootip"
                        style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.duracao" type="time" class="form-control"
                      placeholder="Duração de serviço" maxlength="150">
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="">
                      Link de venda pela internet:
                      <a aria-label="Se você tem um página de seu negócio na internet, produto ou serviço, insira nesta área"
                        data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.website" type="text" class="form-control" placeholder="http://">
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">
                      <i class="fab fa-youtube"></i>Link de video:
                      <a aria-label="Copie e cole abaixo um video do Youtube® que apresente seu produto ou serviço"
                        data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.youtube" type="text" class="form-control" placeholder="http://">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5 col-sm-12">
                  <div class="form-group mb-sm-0 mb-xs-0">
                    <label for="">Valor (R$):
                      <a aria-label="Digite o valor em R$ do seu produto ou serviço" data-balloon-pos="up"
                        class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.valor" type="text" class="form-control alinharEsquerda"
                      placeholder="R$ 0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                  </div>
                </div>
                <div class="col-lg-9 col-md-7 col-sm-12 pt-md-4 mb-3">
                  <small>
                    <strong>
                      O preenchimento de preço não é obrigatório, mas os produtos e serviços precificados tendem a
                      atrair mais a atenção dos consumidores.
                    </strong>
                  </small>
                </div>

              </div>

            </div>

            <div *ngIf="tipoCadastro == 'produto'">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">
                      <i class="fab fa-youtube"></i>Link de video:
                      <a aria-label="Copie e cole abaixo um video do Youtube® que apresente seu produto ou serviço"
                        data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.youtube" type="text" class="form-control" placeholder="http://">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">
                      Link de venda pela internet:
                      <a aria-label="Se você tem um página de seu negócio na internet, produto ou serviço, insira nesta área"
                        data-balloon-pos="up" class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.website" type="text" class="form-control"
                      placeholder="Copie a URL e cole aqui" maxlength="150">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-5 col-sm-12">
                  <div class="form-group mb-sm-0 mb-xs-0">
                    <label for="">Valor (R$):
                      <a aria-label="Digite o valor em R$ do seu produto ou serviço" data-balloon-pos="up"
                        class="tootip" style="cursor: pointer;">
                        <i class="fas fa-question-circle"></i>
                      </a>
                    </label>
                    <input [(ngModel)]="servico.valor" type="text" class="form-control alinharEsquerda"
                      placeholder="R$ 0,00" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                  </div>
                </div>
                <div class="col-lg-9 col-md-7 col-sm-12 pt-md-4 mb-3">
                  <small>
                    <strong>
                      O preenchimento de preço não é obrigatório, mas os produtos e serviços precificados tendem a
                      atrair mais a atenção dos consumidores.
                    </strong>
                  </small>
                </div>



              </div>
            </div>
          </div>
        </div>

        <div class="custom-control custom-checkbox custom-control-inline" data-toggle="collapse" href=".add-produto"
          role="button" aria-expanded="false" aria-controls="" *ngIf="show_check_mesmo_endereco">
          <input type="checkbox" class="custom-control-input" id="check1" [checked]="servico.mesmo_endereco"
            (change)="servico.mesmo_endereco = !servico.mesmo_endereco">
          <label class="custom-control-label" for="check1">
            <p>
              O local de venda física deste serviço é o mesmo do endereço do cadastro.
            </p>
          </label>
        </div>
        <div class="add-produto" id="" *ngIf="!servico.mesmo_endereco">

          <div class="card card-body">
            <h5>ENDEREÇO DE VENDA FISICA</h5>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">CEP: <span>*</span></label>
                  <div class="input-group input-group-right" [ngClass]="displayFieldCss(servico.cep)">
                    <input type="text" class="form-control" placeholder="CEP" mask="00.000-000" [ngModel]="servico.cep"
                      (ngModelChange)=getEndereco($event)>
                    <app-field-error-display [displayError]="isFieldValid(servico.cep)"
                      errorMsg="Por favor, informe o CEP">
                    </app-field-error-display>

                    <span class="input-group-text input-icon info">
                      <i class="fas fa-question-circle"></i>
                      <small class="tooltip">Coloque seu CEP e o sistema preencherá automaticamente seu endereço</small>
                    </span>


                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="form-group" [ngClass]="displayFieldCss(servico.endereco)">
                  <label for="">Endereço: <span>*</span></label>
                  <input [(ngModel)]="servico.endereco" type="text" class="form-control" placeholder="Endereço"
                    maxlength="150" (change)="obterCoordenadas()">
                  <app-field-error-display [displayError]="isFieldValid(servico.endereco)"
                    errorMsg="Por favor, informe o Endereço">
                  </app-field-error-display>
                </div>
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="form-group">
                  <label for="">Número:</label>
                  <input [(ngModel)]="servico.numero" type="text" class="form-control" placeholder="Número"
                    maxlength="10">
                </div>
              </div>
              <div class="col-md-10 col-sm-12">
                <div class="form-group">
                  <label for="">Complemento:</label>
                  <input [(ngModel)]="servico.complemento" type="text" class="form-control" placeholder="Complemento"
                    maxlength="150">
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group" [ngClass]="displayFieldCss(servico.bairro)">
                  <label for="">Bairro: <span>*</span></label>
                  <input [(ngModel)]="servico.bairro" type="text" class="form-control" placeholder="Bairro"
                    maxlength="150" (change)="obterCoordenadas()">
                  <app-field-error-display [displayError]="isFieldValid(servico.bairro)"
                    errorMsg="Por favor, informe o Bairro">
                  </app-field-error-display>
                </div>
              </div>
              <div class="col-md-2 col-sm-2">
                <div class="form-group" [ngClass]="displayFieldCss(servico.id_uf)">
                  <label for="">UF: <span>*</span></label>
                  <!-- <input [(ngModel)]="servico.uf" type="text" class="form-control" placeholder="" maxlength="2"> -->
                  <select class="form-control" [(ngModel)]="servico.id_uf" (ngModelChange)="getCidadeList()" disabled>
                    <option value="">Selecione</option>
                    <option *ngFor="let opt of ufList" [value]="opt.nome">
                      {{ opt.nome }}
                    </option>
                  </select>
                  <app-field-error-display [displayError]="isFieldValid(servico.id_uf)"
                    errorMsg="Por favor, informe a Estado">
                  </app-field-error-display>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group" [ngClass]="displayFieldCss(servico.id_cidade)">
                  <label for="">Cidade: <span>*</span></label>
                  <!-- <input [(ngModel)]="servico.cidade" type="text" class="form-control" placeholder="" maxlength="150"> -->
                  <select class="form-control" [(ngModel)]="servico.id_cidade" disabled>
                    <option value="">Selecione</option>
                    <option *ngFor="let opt of cidadeList" [value]="opt.id">
                      {{ opt.nome }}
                    </option>
                  </select>
                  <app-field-error-display [displayError]="isFieldValid(servico.id_cidade)"
                    errorMsg="Por favor, informe a Cidade">
                  </app-field-error-display>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-end">
          <a class="btn btn-transparent">
            <i class="fas fa-plus"></i>
            cadastrar endereço DE VENDA FÍSICA
          </a>
        </div> -->



        </div>
        <div class="w-100">
          <div class="flex justify-content-end">
            <button type="button" class="btn btn-danger btn-sm " (click)="mudarTela('lista', true)">
              <i class="fas fa-times"></i>
              CANCELAR
            </button>
            <button *permission="['add-product-or-service']" type="button" class="btn btn-success btn-sm"
              (click)="AdicionarServico()" [disabled]="SalvarClicado">
              <i class="fas fa-check"></i>
              {{botaoPersistencia}}
            </button>
          </div>
        </div>
      </div>
      <!-- --------------------FIM BLOCO CADASTRO------------------------- -->
      <!-- <div class="form-footer mt-3">
        <button class="btn btn-transparent" (click)="AdicionarServico()">
          <i class="fas fa-plus"></i>
          {{botaoPersistencia}}
        </button>
      </div>


      <div class="form-footer mt-5">
        <button type="button" class="btn btn-transparent" (click)="Voltar()">
          <i class="fas fa-arrow-left"></i>
          VOLTAR
        </button>

        <button type="button" class="btn btn-transparent " data-toggle="modal" data-target=".modal-cadastrar"
          (click)="Concluir()">
          <i class="fas fa-arrow-right"></i>
          CONCLUIR
        </button>
      </div> -->
    </div>

    <div *ngIf="tipoTela == 'lista'">

      <button type="button" class="btn btn-transparent azul" (click)="mudarTela('cadastro', false)">
        <i class="fas fa-plus"></i>
        Cadastrar Novo Produto ou Serviço
      </button>
      <!-- =============LISTA PRODUTOS=================== -->
      <app-lista-servico></app-lista-servico>
      <!-- =============FIM LISTA PRODUTOS=================== -->
    </div>


  </div>



</main>