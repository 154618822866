<section class="produtos-servicos destaque-blocos template-0{{templateAleatorio ? 1 : 2}}" *ngIf="servicos.length > 0">
  <div class="center">
    <h2>{{tipo === 'Produto' ? menuService.RetornarDestaqueGerenciador('produtos')?.Titulo :
      menuService.RetornarDestaqueGerenciador('servicos')?.Titulo}}</h2>
    <div class="grid">
      <ul class="list coluna">
        <li class="card" *ngFor="let servico of servicos">
          <a (click)="clickInteresse(servico, $event)" style="cursor: pointer;"></a>
          <div class="user menor" (click)="irParaPessoa(servico.id_pessoa)">
            <img src="{{servico.foto_pessoa}}" alt="">
          </div>
          <div class="like" (click)="AddFavorite(servico.id, $event)" *ngIf="exibeFavoritos">
            <input type="checkbox" class="custom-control-input" [checked]='servico.favorito' id="{{'chk'+servico.id}}">
            <label class="custom-control-label" for="{{'chk'+servico.id}}">
              <i class="far fa-heart"></i>
            </label>
          </div>
          <img src="{{servico.foto}}" alt="">

          <div class="card-footer">
            <div class="texto">
              <h3>{{servico.nome}}</h3>
              <p>{{servico.descricao}}</p>
            </div>
            <div class="info">
              <strong *ngIf="servico.valor == 0"> </strong><!-- alt+0160 -->
              <strong *ngIf="servico.valor != 0">{{servico.valor | currency: 'BRL'}}</strong>
              <app-avaliacao-estrela pesId={{servico.id_pessoa}}></app-avaliacao-estrela>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="ver-mais">
      <button type="button" class="btn btn-transparent"
        (click)="irParaTodos(tipo === 'Produto' ? menuService.RetornarDestaqueGerenciador('produtos').Titulo : menuService.RetornarDestaqueGerenciador('servicos').Titulo)">
        {{tipo === 'Produto' ? menuService.RetornarDestaqueGerenciador('ver_produtos').Titulo :
        menuService.RetornarDestaqueGerenciador('ver_servicos').Titulo}}
        <i
          class="{{tipo === 'Serviços' ? menuService.RetornarDestaqueGerenciador('ver_produtos').CssClass : menuService.RetornarDestaqueGerenciador('ver_servicos').CssClass}}"></i>
      </button>
    </div>

  </div>
</section>