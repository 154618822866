import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ServicoService } from '../service/servico.service';
import { CepService } from '../service/cep.service';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from '../service/upload.service';
import { EventService, EventNames } from '../service/event.service';
import { AuthService } from '../service/auth.service';
import { SharedDataService } from '../service/shared-data.service';
import { CidadeService } from '../service/cidade.service';
import { UsuarioService } from '../service/usuario.service';
import { environment } from '.env';
import { PessoaService } from '../service/pessoa.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCropperComponent } from '../image-cropper/modal/modal-cropper.component';
import { GeocodeService } from '../service/geocode.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-servico',
  templateUrl: './servico.component.html',
  styleUrls: ['./servico.component.css'],
})
export class ServicoComponent implements OnInit {
  tamanhoMaximoImagem = environment.MAX_SIZE_UPLOAD / 1000000;
  isSubmited = false;
  servico: any;
  tipoTela = 'lista';
  tipoCadastro = 'servico';
  nomeCadastro = 'servico';
  edit = false;
  subcategoriaDisabledToggle: boolean = true;
  categoriaList: any;
  subcategoriaList: any;
  painelCEP = 'disabled';
  templateFoto = 'assets/images/produto.svg';
  semFoto = true;
  categoriaSelect = '';
  fileToUpload: any;
  //botaoPersistencia = 'ADICIONAR SERVIÇO';
  botaoPersistencia = 'SALVAR';
  cidadeList: any;
  ufList: any;
  show_check_mesmo_endereco = true;
  isAdmin = false;
  SalvarClicado = false; //controla o clique do botão salvar
  tipo = 'externo'; // cor do título
  subcategoriaSelect = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public servicoService: ServicoService,
    private cepService: CepService,
    private toastr: ToastrService,
    private upload: UploadService,
    private event: EventService,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private cidadeService: CidadeService,
    private usuarioService: UsuarioService,
    private pessoaService: PessoaService,
    private modalService: NgbModal,
    private geocodeService: GeocodeService
  ) {
    this.servico = {
      nome: '',
      descricao: '',
      valor: 0,
      cep: '',
      endereco: '',
      bairro: '',
      id_uf: 'BA',
      id_cidade: '140713ad-ba01-11ea-a5ee-42010a4b6008',
      id_categoria: '',
      id_subcategoria: '',
      cadastur: '',
      mesmo_endereco: true,
      destaque: false,
    };
  }

  ngOnInit(): void {
    this.isAdmin =
      this.authService.getUser().nome_perfil == 'Administrador' ? true : false;

    if (
      !this.servicoService.usuario.id &&
      (this.router.url.replace('/', '') == 'adm-servico' ||
        this.router.url.replace('/', '') == 'adm-servico-lista')
    ) {
      this.router.navigate(['home']);
      return;
    }

    if (
      this.router.url.replace('/', '') == 'adm-servico' ||
      this.router.url.replace('/', '') == 'adm-servico-lista'
    ) {
      this.tipo = 'interno';
    }

    this.tipoCadastro = this.route.snapshot.data['tipo'] || 'servico';

    this.tipoTela = this.route.snapshot.data['tipoTela'] || 'lista';
    //this.tipoTela = this.sharedDataService.getData()?.tipoTela || "lista";
    // if (this.router.url.replace('/', '') != 'adm-servico' && this.router.url.replace('/', '') != 'adm-servico-lista') {
    //   this.servicoService.usuario = { id: '', nome: '' };
    // }

    this.getCategoriaList();
    this.getUfList();
    this.servico.id_uf = 'BA';
    this.getCidadeList();
    this.servico.id_cidade = '140713ad-ba01-11ea-a5ee-42010a4b6008';

    this.event.subscribe(EventNames.SHOULD_EDIT_SERVICO, (id) => {
      this.loadEdit(id);
      this.mudarTela('cadastro', false);
      this.edit = true;
      this.botaoPersistencia = 'ATUALIZAR';
      if (!this.servico.foto) {
        this.semFoto = true;
      }
    });

    this.carregaTela();
    this.resolveCheckMesmoEndereco();
  }

  resolveCheckMesmoEndereco() {
    this.pessoaService
      .getCidade(
        this.servicoService.usuario.id || this.authService.getUser().id
      )
      .subscribe((next) => {
        let cidade = next;
        if (cidade.nome != 'Salvador') {
          this.servico.mesmo_endereco = false;
          this.show_check_mesmo_endereco = false;
        }
      });
  }

  loadEdit(id: string) {
    if (id != null && id != undefined) {
      this.getServico(id);
      this.templateFoto = this.servico.templateFoto;
    }
  }

  Concluir() {
    console.log(this.tipoCadastro);
  }

  mudouTipoCadastro() {
    this.router.navigate(['produto']);
  }

  Voltar() {
    this.router.navigate(['pessoa']);
    //this.location.back();
  }

  limparTela() {
    this.servico = {};
    this.subcategoriaSelect = '';
    this.templateFoto = 'assets/images/produto.svg';
    //this.botaoPersistencia = 'ADICIONAR SERVIÇO';
    this.botaoPersistencia = 'SALVAR';
  }

  getServico(id: string) {
    this.servicoService.getServico(id).subscribe(
      (next) => {
        let subcategoria = next.servico.subcategoria;
        this.servico = next.servico;
        this.servico.youtube = this.servico.youtube
          ? `https://youtu.be/${this.servico.youtube}`
          : '';
        //this.templateFoto = this.servico.foto ? `${this.servicoService.getUrl()}images/${this.servico.foto}` : 'assets/images/produto.svg';

        if (!this.servico.foto) {
          this.semFoto = true;
        } else {
          this.semFoto = false;
        }

        let fotoCat;
        if (this.servico.categoria == 'Afroempreendedores') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
        } else if (this.servico.categoria == 'Baiana') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
        } else if (this.servico.categoria == 'Capoeira') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
        } else if (this.servico.categoria == 'Arte & Cultura') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
        } else if (this.servico.categoria == 'Flora') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
        } else if (this.servico.categoria == 'Gastronomia') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
        } else if (this.servico.categoria == 'Moda & Beleza') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
        } else if (this.servico.categoria == 'Religião') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
        } else if (this.servico.categoria == 'Turismo') {
          fotoCat =
            'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
        } else {
          fotoCat = 'assets/images/produto.svg';
        }

        this.templateFoto = this.servico.foto || fotoCat;
        this.tipoCadastro = this.servico.tipo == 'S' ? 'servico' : 'produto';
        var idsub = this.servico.id_subcategoria;
        this.getSubcategoriaList();
        this.servico.id_subcategoria = idsub;
        this.subcategoriaSelect = subcategoria;
        //this.servico.valor = this.servico.valor.replace('.', ',')
      },
      (error) => this.toastr.error('Ocorreu um erro', 'Erro')
    );
  }

  getSubcategoriaNome(id: string) {
    if (!id) {
      this.subcategoriaSelect = '';
    }

    this.servicoService.getSubcategoriaCompleta({ id }).subscribe((res) => {
      this.subcategoriaSelect = res.nome;
    });
  }

  getSubcategoriaList() {
    this.servicoService
      .getCategoriaCompleta({ id: this.servico.id_categoria })
      .subscribe((res) => {
        if (res) {
          this.categoriaSelect = res.nome;
          if (!this.servico.foto) {
            if (res.nome == 'Afroempreendedores') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
            } else if (res.nome == 'Baiana') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
            } else if (res.nome == 'Capoeira') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
            } else if (res.nome == 'Arte & Cultura') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
            } else if (res.nome == 'Flora') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
            } else if (res.nome == 'Gastronomia') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
            } else if (res.nome == 'Moda & Beleza') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
            } else if (res.nome == 'Religião') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
            } else if (res.nome == 'Turismo') {
              this.templateFoto =
                'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
            } else {
              this.templateFoto = 'assets/images/produto.svg';
            }
          }
        } else {
          if (this.semFoto) {
            this.templateFoto = 'assets/images/produto.svg';
          }
        }
      });

    this.subcategoriaList = [];
    this.subcategoriaDisabledToggle = true;
    this.servico.id_subcategoria = '';
    if (this.servico && this.servico.id_categoria) {
      this.servicoService
        .getSubcategoriaList(this.servico.id_categoria)
        .subscribe((list) => {
          this.subcategoriaList = list;
          if (this.subcategoriaList.length > 0) {
            this.subcategoriaDisabledToggle = false;
          }
        });
    }
  }

  getCategoriaList() {
    this.servicoService
      .getCategoriaList()
      .subscribe((list) => (this.categoriaList = list));
  }

  getEndereco(cep: string) {
    this.servico.cep = '';
    let _cep = cep.split('.').join('').split('-').join('');
    if (_cep.length == 8) {
      this.painelCEP = ''; //remove disabed do painel
      this.cepService.getEndereco(_cep).subscribe((next: any) => {
        if (!next.erro) {
          if (next.localidade != 'Salvador') {
            this.toastr.error('Somente CEP de Salvador/BA', 'Erro');
            return;
          }
          let endereco = next;

          this.servico.endereco = endereco.logradouro;
          this.servico.bairro = endereco.bairro;
        }
        this.obterCoordenadas();
      });
      this.servico.cep = _cep;
    }
  }

  validarCadastur(cadastur: string) {
    if (cadastur.length < 11) {
      return false;
    }

    if (cadastur.length > 11 && cadastur.length < 14) {
      return false;
    }

    if (cadastur.length > 14) {
      return false;
    }

    return true;
  }

  AdicionarServico() {
    if (this.semFoto) {
      const FotoCad = 'Foto não cadastrada.';
      const frase = 'Deseja cadastrar seu produto ou serviço sem foto?';

      if (this.subcategoriaSelect == 'Guias de Turismo') {
        if (!this.validarCadastur(this.servico.cadastur)) {
          this.toastr.error('Número do cadastur inválido', 'Erro');
          return;
        }
      }

      Swal.fire({
        title: FotoCad.bold(),
        text: frase,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.isSubmited = true;

          if (!this.validateField()) {
            return;
          }
          this.SalvarClicado = true;
          //campos somente para tipo de cadastro Serviço
          if (this.tipoCadastro == 'produto') {
            this.servico.agenda = undefined;
            this.servico.duracao = undefined;
          }

          if (!this.servico.mesmo_endereco) {
            this.obterCoordenadas();
          }

          this.servico.youtube = this.formataLinkYoutube(this.servico.youtube);

          if (
            this.router.url.replace('/', '') == 'adm-servico' ||
            this.router.url.replace('/', '') == 'adm-servico-lista'
          ) {
            this.servico.id_usuario = this.servicoService.usuario.id;
          } else {
            this.servico.id_usuario = this.authService.getUser().id;
          }

          setTimeout(() => {
            if (!this.edit) {
              this.servicoService.addServico(this.servico).subscribe(
                (_) => {
                  this.SalvarClicado = false;
                  this.toastr.success('Serviço adicionado', 'Sucesso');
                  this.servicoService.isFromRoute = '';
                  this.event.publish(EventNames.SHOULD_UPDATE_PRODUCT_LIST);
                  this.mudarTela('lista', false);
                },
                (error) => {
                  this.SalvarClicado = false;
                  this.toastr.error(error.error, 'Erro');
                }
              );
            } else {
              this.servicoService.updateServico(this.servico).subscribe(
                (_) => {
                  this.SalvarClicado = false;
                  this.toastr.success('Serviço atualizado', 'Sucesso');
                  this.servicoService.isFromRoute = '';
                  this.event.publish(EventNames.SHOULD_UPDATE_PRODUCT_LIST);
                  this.mudarTela('lista', false);
                },
                (error) => {
                  this.SalvarClicado = false;
                  this.toastr.error(error.error, 'Erro');
                }
              );
            }
          }, 1000);
        }
      });
    } else {
      this.isSubmited = true;

      if (!this.validateField()) {
        return;
      }
      this.SalvarClicado = true;
      //campos somente para tipo de cadastro Serviço
      if (this.tipoCadastro == 'produto') {
        this.servico.agenda = undefined;
        this.servico.duracao = undefined;
      }

      if (!this.servico.mesmo_endereco) {
        this.obterCoordenadas();
      }

      this.servico.youtube = this.formataLinkYoutube(this.servico.youtube);

      if (
        this.router.url.replace('/', '') == 'adm-servico' ||
        this.router.url.replace('/', '') == 'adm-servico-lista'
      ) {
        this.servico.id_usuario = this.servicoService.usuario.id;
      } else {
        this.servico.id_usuario = this.authService.getUser().id;
      }

      setTimeout(() => {
        if (!this.edit) {
          this.servicoService.addServico(this.servico).subscribe(
            (_) => {
              this.SalvarClicado = false;
              this.toastr.success('Serviço adicionado', 'Sucesso');
              this.servicoService.isFromRoute = '';
              this.event.publish(EventNames.SHOULD_UPDATE_PRODUCT_LIST);
              this.mudarTela('lista', false);
            },
            (error) => {
              this.SalvarClicado = false;
              this.toastr.error(error.error, 'Erro');
            }
          );
        } else {
          this.servicoService.updateServico(this.servico).subscribe(
            (_) => {
              this.SalvarClicado = false;
              this.toastr.success('Serviço atualizado', 'Sucesso');
              this.servicoService.isFromRoute = '';
              this.event.publish(EventNames.SHOULD_UPDATE_PRODUCT_LIST);
              this.mudarTela('lista', false);
            },
            (error) => {
              this.SalvarClicado = false;
              this.toastr.error(error.error, 'Erro');
            }
          );
        }
      }, 1000);
    }
  }

  obterCoordenadas() {
    if (this.servico.endereco && this.servico.bairro) {
      let nomeCidade = this.cidadeList.find(
        (x: any) => x.id == this.servico.id_cidade
      ).nome;
      let filtro = `${this.servico.endereco}+${this.servico.numero}+${this.servico.bairro}+${nomeCidade}`;
      this.geocodeService.getCoordinate(filtro).subscribe((next: any) => {
        let res = next.results[0].geometry.location;
        this.servico.latitude = res.lat;
        this.servico.longitude = res.lng;
      });
    }
  }

  handleFileInput(event: any) {
    this.fileToUpload = event.target.files.item(0);

    if (!this.fileToUpload) {
      return;
    }

    if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
      this.toastr.error(
        `Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`
      );
      return;
    }

    const modalRef = this.modalService.open(ModalCropperComponent);
    modalRef.componentInstance.imageChangedEvent = event;
    modalRef.componentInstance.aspectRatio = 4 / 3;
    modalRef.componentInstance.showControlPanel = true; // apagar depois
    modalRef.componentInstance.clickevent.subscribe(($e: any) => {
      if ($e) {
        this.semFoto = false;
      } else {
        this.semFoto = true;
      }
      this.upload.upload($e).subscribe((x) => {
        this.servico.foto = x.persistentUrl;
        this.templateFoto = $e;
        //this.spinFoto = false;
      });
    });
    // this.fileToUpload = files.files.item(0);

    // if (!this.fileToUpload) {
    //   return;
    // }

    // if (this.fileToUpload.size > environment.MAX_SIZE_UPLOAD) {
    //   this.toastr.error(`Tamanho máximo de imagem permitido é ${this.tamanhoMaximoImagem}Mb`)
    //   return;
    // }

    // var reader = new FileReader();
    // reader.onload = () => {
    //   this.upload.upload(reader.result).subscribe(x => {
    //     this.servico.foto = x.persistentUrl;
    //     this.templateFoto = x.url
    //   });
    // }
    // reader.readAsDataURL(this.fileToUpload);
  }

  AlterarFoto() {}

  ExcluirFoto() {
    this.servico.foto = null;

    if (this.categoriaSelect == 'Afroempreendedores') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-afroempreendedores.svg';
    } else if (this.categoriaSelect == 'Baiana') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-baiana.svg';
    } else if (this.categoriaSelect == 'Capoeira') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-capoeira.svg';
    } else if (this.categoriaSelect == 'Arte & Cultura') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-cultura.svg';
    } else if (this.categoriaSelect == 'Flora') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-flora.svg';
    } else if (this.categoriaSelect == 'Gastronomia') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-gastronomia.svg';
    } else if (this.categoriaSelect == 'Moda & Beleza') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-moda_beleza.svg';
    } else if (this.categoriaSelect == 'Religião') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-religiao.svg';
    } else if (this.categoriaSelect == 'Turismo') {
      this.templateFoto =
        'assets/images/categoria_perfil_padrao/img-padrao-turismo.svg';
    } else {
      this.templateFoto = 'assets/images/produto.svg';
    }

    this.semFoto = true;
  }

  carregaTela() {
    if (this.tipoCadastro == 'servico') {
      this.nomeCadastro = 'Serviço';
      this.servico.tipo = 'S';
    }

    if (this.tipoCadastro == 'produto') {
      this.nomeCadastro = 'Produto';
      this.servico.tipo = 'P';
    }
  }

  mudarTela(tipoTela: string, cancelar: boolean) {
    const isFromRoute = this.servicoService.isFromRoute;
    this.servicoService.isFromRoute = '';
    if (isFromRoute && tipoTela == 'lista' && cancelar) {
      this.router.navigate([isFromRoute]);
      return;
    }
    this.tipoTela = tipoTela;
    this.limparTela();
    this.edit = false;
    this.objetoIncial();
    this.isSubmited = false;
    this.resolveCheckMesmoEndereco();
  }

  objetoIncial() {
    this.servico = {
      nome: '',
      descricao: '',
      valor: 0,
      cep: '',
      endereco: '',
      bairro: '',
      id_uf: 'BA',
      id_cidade: '140713ad-ba01-11ea-a5ee-42010a4b6008',
      id_categoria: '',
      id_subcategoria: '',
      mesmo_endereco: true,
      tipo: this.tipoCadastro == 'servico' ? 'S' : 'P',
    };
  }

  novoCadastro() {}

  isFieldValid(field: any) {
    return this.isSubmited && field == '';
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }

  validateField() {
    const fields = [
      'nome',
      'descricao',
      //'valor',
      'id_categoria',
      'id_subcategoria',
    ];

    const fieldsEndereco = ['cep', 'endereco', 'bairro', 'id_uf', 'id_cidade'];

    for (const iterator of fields) {
      if (this.servico[iterator] == '') {
        return false;
      }
    }

    if (!this.servico.mesmo_endereco) {
      for (const iterator of fieldsEndereco) {
        if (this.servico[iterator] == '') {
          return false;
        }
      }
    }

    return true;
  }

  getCidadeList() {
    this.cidadeService
      .getList(this.servico.id_uf)
      .subscribe((list) => (this.cidadeList = list));
  }

  getUfList() {
    this.cidadeService.getUfList().subscribe((list) => (this.ufList = list));
  }

  redirecionarDadosGerais() {
    this.usuarioService.usuario = this.servicoService.usuario;
    this.router.navigate(['adm-cadastrar-dados-pessoais']);
  }

  formataLinkYoutube(link: string) {
    let newval: any = [];
    let _link = link || '';

    if ((newval = _link.match(/(\?|&)v=([^&#]+)/))) {
      _link = newval.pop();
    } else if ((newval = _link.match(/(\.be\/)+([^\/]+)/))) {
      _link = newval.pop();
    } else if ((newval = _link.match(/(\embed\/)+([^\/]+)/))) {
      _link = newval.pop().replace('?rel=0', '');
    }

    return _link;
  }
}
