<div class="lista-produtos">

  <div class=" filtro form-row align-items-center">

    <label for="">
      Filtros
    </label>
    <div class="col">
      <div class="form-group group-select">

        <select class="form-control" [(ngModel)]="filtro.tipo">
          <option value="">Escolha o tipo</option>
          <option *ngFor="let opt of tipoCadastroList" [value]="opt.id">
            {{ opt.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group group-select">

        <select class="form-control" [(ngModel)]="filtro.id_categoria" (ngModelChange)="getSubcategoriaList()">
          <option value="">Categoria</option>
          <option *ngFor="let opt of categoriaList" [value]="opt.id">
            {{ opt.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group group-select">

        <select class="form-control " [(ngModel)]="filtro.id_subcategoria" [disabled]="subcategoriaDisabledToggle">
          <option value="">Subcategoria</option>
          <option *ngFor="let opt of subcategoriaList" [value]="opt.id">
            {{ opt.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <input [(ngModel)]="filtro.descricao" type="text" class="form-control"
          placeholder="escreva o que deseja buscar">
      </div>
    </div>
    <button class="btn btn-transparent" (click)="filtrar()">
      <i class="fas fa-filter"></i>
      <span>filtrar</span>
    </button>
  </div>
  <div class="d-flex justify-content-center" *ngIf="produtoList.count <= 0">
    <span class="mt-5">Sem registros</span>
  </div>

  <ul class="list" *ngIf="produtoList.count > 0">

    <!-- ==============ITEM LISTA=============== -->
    <li *ngFor="let item of produtoList.rows">
      <div class="image">
        <span class="categoria bg-vermelho">{{item['Subcategorium.nome']}}</span>
        <img src='{{item.foto}}' alt="">
      </div>
      <div class="texto">
        <small>{{ item.tipo == 'P' ? 'PRODUTO' : 'SERVIÇO'}}</small>
        <h5>{{item.nome}}</h5>
        <p>
          <strong>Descrição:</strong>
          {{item.descricao}}
        </p>
        <div class="flex">
          <span>
            <strong>Valor: </strong>
            {{item.valor | currency:'BRL' }}
          </span>
          <a class="btn btn-transparent" [href]='item.website' target="_blank" style="cursor: pointer;">
            <i class="fas fa-link"></i>
            Link de venda</a>
        </div>
      </div>
      <div class="botoes">
        <div *ngIf="isAdmin">
          <button type="button" class="btn btn-transparent" (click)="Destacar(item.id)">
            <i *ngIf="item.destaque" class="fas fa-star"></i>
            <i *ngIf="!item.destaque" class="far fa-star"></i>
          </button>
        </div>
        <button type="button" class="btn btn-transparent" (click)="Editar(item.id, item.tipo)">
          <i class="fas fa-edit"></i>
        </button>
        <button type="button" class="btn btn-transparent" (click)="Detete(item.id)">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </li>
    <!-- ==============FIM ITEM LISTA=============== -->
  </ul>
  <div class="d-flex justify-content-center" *ngIf="qtdPaginas > 1">
    <nav>
      <ul class="pagination">
        <li class="page-item ">
          <a class="page-link" *ngIf="paginaAtual == 0" style="cursor: pointer;"><i class="fas fa-chevron-left"></i></a>
          <button class="page-link" (click)="getProdutoList(paginaAtual - 1)" *ngIf="paginaAtual != 0">
            <i class="fas fa-chevron-left"></i>
          </button>
        </li>
        <li class="page-item " *ngFor="let opt of [].constructor(qtdPaginas); let i = index">
          <a class="page-link active" *ngIf="paginaAtual == i" style="cursor: pointer;">{{ i + 1 }}</a>
          <button class="page-link" (click)="getProdutoList(i)" *ngIf="paginaAtual != i">
            {{ i + 1 }}
          </button>
        </li>
        <li class="page-item">
          <a class="page-link" *ngIf="paginaAtual == (qtdPaginas-1)" style="cursor: pointer;"><i
              class="fas fa-chevron-right"></i></a>
          <button class="page-link" (click)="getProdutoList(paginaAtual + 1)" *ngIf="paginaAtual != (qtdPaginas-1)">
            <i class="fas fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>